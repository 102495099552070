import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Space,
  TimePicker,
  message,
  Spin,
} from "antd";
import "./BookingComponent.scss";
import dayjs from "dayjs";
import {
  CreateBookingWithLoginAPI,
  CreateBookingWithoutLoginAPI,
} from "../../Constants/Api/BookingsApi/BookingApi";
import { getLocalStorageItem } from "../../Constants/Api/locastorageHandle";

const SubmitButton = ({ form, loading }) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable || loading}
      className="button_theme"
    >
      {loading && <Spin size="small" />} Book Your Service
    </Button>
  );
};

const BookingComponent = ({ isVisible, onClose, serviceID }) => {
  const UserToken = getLocalStorageItem("UserLoginToken");
  const GuestToken = getLocalStorageItem("GuestLoginToken");
  const Userguest = getLocalStorageItem("Userguest_id");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); // Loading state

  const onChange = (date, dateString) => {};

  const resetForm = () => {
    form.resetFields();
  };

  const onFinish = (values) => {
    setLoading(true); // Set loading to true on form submission
    const formattedDate = dayjs(values.date).format("YYYY-MM-DD");
    const formattedTime = dayjs(values.time).format("HH:mm");
    const headers = {
      "x-access-token": Userguest === "LoginUser" ? UserToken : GuestToken,
    };
    let formData;
    if (Userguest === "LoginUser") {
      formData = {
        service_id: serviceID,
        date: formattedDate,
        time: formattedTime,
        pickup_location: values.pickuplocation,
        destination_location: values.destinationlocation,
      };

      CreateBookingWithLoginAPI(formData, headers)
        .then((res) => {
          message.success(res?.data?.message);
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(() => {
          setLoading(false); // Reset loading after the API call
          onClose();
        });
    } else {
      formData = {
        service_id: serviceID,
        date: formattedDate,
        time: formattedTime,
        pickup_location: values?.pickuplocation,
        destination_location: values?.destinationlocation,
        name: values?.username,
        email: values?.userenamil,
        contact_number: values?.userphone,
      };

      CreateBookingWithoutLoginAPI(formData, headers)
        .then((res) => {
          message.success(res?.data?.message);
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(() => {
          setLoading(false); // Reset loading after the API call
          onClose();
        });
    }
  };

  const format = "HH:mm";

  return (
    <Modal visible={isVisible} onCancel={onClose} footer={null}>
      <div className="BookingComponent">
        <h5>Residential Moving Services</h5>
        <h6>(Book Us for a Smooth Move)</h6>
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          className="form_layout"
        >
          {Userguest !== "LoginUser" && (
            <>
              <Form.Item
                name="username"
                label="User Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="User Email"
                name="userenamil"
                rules={[
                  {
                    required: true,
                    message: "Please input your useremail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="User Phone"
                name="userphone"
                rules={[
                  {
                    required: true,
                    message: "Please input your userphone!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          )}
          
          <Form.Item
            name="pickuplocation"
            label="Pickup Location"
            rules={[
              {
                required: true,
                message: "Please input your Pickup Location!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="destinationlocation"
            label="Destination Location"
            rules={[
              {
                required: true,
                message: "Please input your Destination Location!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Space>
            <Form.Item
              name="date"
              label="Select Date"
              rules={[
                {
                  required: true,
                  message: "Please Select Date!",
                },
              ]}
            >
              <DatePicker onChange={onChange} />
            </Form.Item>
            <Form.Item
              name="time"
              label="Select Time"
              rules={[
                {
                  required: true,
                  message: "Please Select Time!",
                },
              ]}
            >
              <TimePicker minuteStep={15} use12Hours format="h:mm a" />
            </Form.Item>
          </Space>
          <Form.Item>
            <Space>
              <SubmitButton form={form} loading={loading} />{" "}
              {/* Pass loading state */}
              <Button
                htmlType="button"
                onClick={resetForm}
                className="button_theme"
              >
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default BookingComponent;
