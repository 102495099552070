import React from "react";
import { Modal, Button } from "antd";

const BookingConfirmationModal = ({
  isVisible,
  onClose,
  onGuestClick,
  onLoginClick,
}) => {
 
  return (
    <Modal
      visible={isVisible}
      title="Booking Confirmation"
      onCancel={onClose}
      footer={[
        <Button className="book_ctm_btn" onClick={onGuestClick}>
          Continue as Guest
        </Button>,
        <Button key="login" className="book_ctm_btn" onClick={onLoginClick}>
          Log In
        </Button>,
      ]}
    >
      <p>Would you like to book as a guest or log in to your account?</p>
    </Modal>
  );
};

export default BookingConfirmationModal;
