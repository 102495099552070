import axios from "axios";
import { message } from "antd";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "./locastorageHandle";
const Base_URL = "https://node.nunamoving.com:8080/api/v1/";
const apiClient = axios.create({
  baseURL: Base_URL,
});
// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    console.log(config, "config");
    // config.headers["Content-Type"] = "multipart/form-data";
    // config.headers["Accept"] = "application/json";
    let token =
      config.headers?.token ||
      getLocalStorageItem("GuestLoginToken") ||
      getLocalStorageItem("UserLoginToken");
    config.headers["x-access-token"] = token;
    config.headers["role_id"] = 6;
    config.headers["timezone"] =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const statusCode = error.response.status;
      const statusMsg = error.response.data.message;
      if (statusCode === 404) {
        // message.error("Session expired. Please log in again.");
        message.error(statusMsg);
        // removeLocalStorageItem("UserLoginToken");
        // removeLocalStorageItem("GuestLoginToken");
        // removeLocalStorageItem("Userguest_id");
        // setTimeout(() => {
        //   window.location.href = "/signin";
        // }, 1000);
      } else if (statusCode === 401 && statusMsg === "Please authenticate") {
        message.error("Your session has expired. Please log in again.");
        // removeLocalStorageItem("UserLoginToken");
        // removeLocalStorageItem("GuestLoginToken");
        // removeLocalStorageItem("Userguest_id");
        // setTimeout(() => {
        //   window.location.href = "/signin";
        // }, 1000);
      } else if (statusCode === 400) {
        message.error(statusMsg);
      } else if (statusCode === 500) {
        message.error("Your session has expired. Please log in again.");
        // removeLocalStorageItem("UserLoginToken");
        // removeLocalStorageItem("GuestLoginToken");
        // removeLocalStorageItem("Userguest_id");
        // setTimeout(() => {
        //   window.location.href = "/signin";
        // }, 1000);
      }
    } else {
      message.error(
        "An unexpected error occurred. Please check your connection."
      );
    }
    return Promise.reject(error);
  }
);
// Exporting API for Login
export const LoginAPI = async (formData, headers) => {
  return await apiClient.post(`user/auth/login`, formData, { headers });
};
export default apiClient;
