import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SignUp from "./Component/Signup/Signup";
import Signin from "./Component/Signin/Signin";
import LandingPage from "./Pages/LandingPage/LandingPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy/Privacy";
import DeleteAccount from "./Pages/DeleteAccount/DeleteAccount";
import { Layout } from "antd";
import Blogs from "./Component/Blogs/Blogs";
import AboutUSComponent from "./Component/AboutUSComponent/AboutUSComponent";
import ServiceDetails from "./Component/ServiceDetails/ServiceDetails";
import ContactUs from "./Component/ContactUs/ContactUs";
import BlogDetails from "./Component/BlogDetails/BlogDetails";
import BookingHistory from "./Component/BookingHistory/BookingHistory";
import Orders from "./Component/Orders/Orders";
import ProfileComponent from "./Component/Profile/ProfileComponent";
import ProductComponent from "./Component/ProductComponent/ProductComponent";
import CheckoutPage from "./Component/CheckoutPage/CheckoutPage";
import VerifyOTP from "./Component/VerifyOtp/VerifyOtp";
import ForgotPassword from "./Component/ForgotPassword/ForgotPassword";
import MovingInventoryForm from "./Component/MovingInventoryForm/MovingInventoryForm";

const WebRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route exact path="/signup" element={<SignUp />} />
      <Route exact path="/form" element={<MovingInventoryForm />} />

      <Route exact path="/signin" element={<Signin />} />
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route exact path="/how-to-delete-account" element={<DeleteAccount />} />
      <Route
        exact
        path="/blogs"
        element={
          <Layout>
            <Blogs />
          </Layout>
        }
      />
      <Route
        exact
        path="/about-us"
        element={
          <Layout>
            <AboutUSComponent />
          </Layout>
        }
      />
      <Route
        exact
        path="/services/:slug"
        element={
          <Layout>
            <ServiceDetails/>
          </Layout>
        }
      />
      <Route
        exact
        path="/contact-us"
        element={
          <Layout>
            <ContactUs />
          </Layout>
        }
      />
      <Route
        exact
        path="/blogs-details"
        element={
          <Layout>
            <BlogDetails/>
          </Layout>
        }
      />
      <Route exact path="/booking-history" element={<BookingHistory />} />
      <Route exact path="/orders" element={<Orders/>} />
      <Route exact path="/profile-edit" element={<ProfileComponent />} />
      <Route exact path="/product-shopping" element={<ProductComponent />} />
      <Route exact path="/chekcout-order" element={<CheckoutPage />} />
      <Route exact path="/forgot-password" element={<ForgotPassword/>} />
      <Route exact path="/how-to-delete-account" element={<DeleteAccount />} />
      <Route exact path="/verifyotp" element={<VerifyOTP/>} />

    </Routes>
  );
};

export default WebRoutes;
