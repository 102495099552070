import React, { useEffect, useState } from "react";
import { Button, Card, Container, Spinner } from "react-bootstrap"; // Import Spinner from react-bootstrap
import { useNavigate } from "react-router-dom";
const MessageTemplate = ({
  subject,
  text,
  buttonText,
  onButtonClick,
  useHtmlText = true,
}) => {
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const navigate = useNavigate();
  // Function to handle the "Back" button click
  const handleBackClick = () => {
    setIsLoading(true); // Set loading to true when button is clicked
    onButtonClick();
    // Simulate a delay, then navigate (replace with your actual logic)
    setTimeout(() => {
      setIsLoading(false); // Reset loading after the delay
      navigate("/form");
    }, 1000); // Example delay of 2 seconds
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);
  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <Card
        style={{
          maxWidth: "1000px",
          textAlign: "center",
          padding: "80px 60px",
        }}
      >
        <div className="main_heading">{/* Optional Heading */}</div>
        <div className="EmailSentMessage m-2">
          <h3>{subject}</h3>
          {useHtmlText ? (
            <p
              dangerouslySetInnerHTML={{ __html: text }}
              style={{ textAlign: "left" }}
            ></p>
          ) : (
            <p style={{ textAlign: "left" }}>{text}</p>
          )}
          <Button
            className="buttontheme"
            onClick={handleBackClick} // Handle the button click
            style={{ border: "none" }}
            disabled={isLoading} // Disable button when loading
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              buttonText
            )}
          </Button>
        </div>
      </Card>
    </Container>
  );
};
export default MessageTemplate;
