import axios from "axios";

export const UserLoginAsGuestAPI = async () => {
  return await axios.get(
    `https://node.nunamoving.com:8080/api/v1/user/auth/guestLogin`,
    null,
    {
      headers: {
        role_id: 6, // Only setting the role_id header for guest login
      },
    }
  );
};
