import axios from "axios";


const Base_URL = "https://node.nunamoving.com:8080/api/v1/";

export const MovingInventoryFormApi = async (formData) => {
  return await axios.post(
    `${Base_URL}form/submitForm`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
